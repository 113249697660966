import React, { useState, useImperativeHandle, forwardRef } from 'react';
import axios from 'axios';
import ConfirmModal from './ConfirmModal'; // 引入確認模組
import Swal from 'sweetalert2';
import BouncyComponent from './BouncyComponent';
import { Table, Checkbox, Select, Button } from 'antd';
import Modal from './Modal'; // 引入一般信息模組

const { Option } = Select;

const InventoryUploader = forwardRef((props, ref) => {
    const [firstNewProducts, setFirstNewProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState({});
    const [loading, setLoading] = useState(false);
    const [completedProducts, setCompletedProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [serverConnected, setServerConnected] = useState(null);
    const [eposConnected, setEposConnected] = useState(null);
    const [checkingConnections, setCheckingConnections] = useState(false);
    const [checkProgress, setCheckProgress] = useState('');
    const [modalContent, setModalContent] = useState({ title: '', message: '', type: '' });
    const [confirmModalContent, setConfirmModalContent] = useState({});
    const [modalAction, setModalAction] = useState(null);

    const allVendors = ['全台', '央廚', '王座-備', '王座-食', '忠欣', '開元', '裕賀', '美食家', '點線麵'];
    const allLayers = ['未使用', '冷藏', '冷凍', '常溫', '清潔', '備品'];
    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    // 使 handleStartInventory 可從父組件調用
    useImperativeHandle(ref, () => ({
        startInventory: handleStartInventory
    }));

    const handleStartInventory = async () => {
        setCheckProgress('正在檢查門市資訊...');
        await delay(1500); // 等待1秒

        if (props.storeName === '') {
            console.error('Store name is required');
            Swal.fire('錯誤', '請先選擇門市。', 'error');
            return;
        }

        setCheckingConnections(true);

        try {
            // 檢查伺服器和 EPOS 連接的狀態
            await checkConnections();
            // 確認連線後的操作
            if (serverConnected === false || eposConnected === false) {
                Swal.fire('錯誤', '無法開始盤點，因為有主機離線。', 'error');
                return;
            }
            setLoading(true);
            const response = await axios.get(`https://inventory.edc-pws.com/api/startInventory/${props.storeName}`);
            setFirstNewProducts(response.data);
            setCompletedProducts(response.data.map(product => ({
                ...product,
                規格: '',
                廠商: '',
                庫別: ''
            })));
        } catch (error) {
            Swal.fire('錯誤', '取得盤點模板時發生錯誤！', 'error');
        } finally {
            setLoading(false);
            setCheckingConnections(false);
        }
    };

    const checkConnections = async () => {
        // 伺服器連接檢查邏輯...
        // 伺服器測試
        try {
            const serverResponse = await axios.get(`https://inventory.edc-pws.com/api/checkConnections`);
            setServerConnected(serverResponse.data.serverConnected);

            const eposResponse = await axios.get(`https://inventory.edc-pws.com/api/ping`);
            setEposConnected(eposResponse.data.eposConnected);
        } catch (error) {
            console.error('Error connecting to server:', error.response ? error.response.data : error.message);
        }
    };

    const handleInputChange = (index, field, value) => {
        setCompletedProducts(prev => {
            const updatedProducts = [...prev];
            updatedProducts[index][field] = value;
            return updatedProducts;
        });
    };

    const toggleSelectProduct = (index) => {
        setSelectedProducts(prev => ({
            ...prev,
            [index]: !prev[index]
        }));
    };

    const toggleSelectAll = (e) => {
        const allSelected = e.target.checked;
        const newSelections = {};
        firstNewProducts.forEach((_, index) => {
            newSelections[index] = allSelected; // 將所有選擇狀態設置為全選或取消選擇
        });
        setSelectedProducts(newSelections);
    };

    const saveCompletedProducts = async () => {
        // 遍歷所有產品，準備好要保存的數據
        const preparedProducts = completedProducts.map((product, index) => ({
            ...product,
            廠商: selectedProducts[index] ? product.廠商 : '未使用',
            庫別: selectedProducts[index] ? product.庫別 : '未使用',
        }));

        try {
            await axios.post(`https://inventory.edc-pws.com/api/saveCompletedProducts/${props.storeName}`, preparedProducts);
            Swal.fire('成功', '數據保存成功！正在刷新盤點數據。', 'success');
            // 延遲3秒後刷新頁面
            setTimeout(() => {
                setCompletedProducts([]);
                setFirstNewProducts([]);
                window.location.reload();
            }, 3000);
        } catch (error) {
            Swal.fire('錯誤', '產品數據保存失敗！', 'error');
        }
    };

    // 表格列配置
    const columns = [
        {
            title: <Checkbox onChange={toggleSelectAll} />,
            dataIndex: 'select',
            render: (_, record, index) => (
                <Checkbox
                    checked={!!selectedProducts[index]}
                    onChange={() => toggleSelectProduct(index)}
                />
            ),
            width: '5%'
        },
        {
            title: '編號',
            dataIndex: '商品編號',
            width: '15%'
        },
        {
            title: '品名',
            dataIndex: '商品名稱',
            width: '50%'
        },
        {
            title: '廠商',
            render: (_, record, index) => (
                <Select
                    value={completedProducts[index]?.廠商 || '未使用'}
                    onChange={value => handleInputChange(index, '廠商', value)}
                    disabled={!selectedProducts[index]}
                    style={{ width: '100%' }}
                >
                    <Option value="未使用" disabled>選擇廠商</Option>
                    {allVendors.map(vendor => (
                        <Option key={vendor} value={vendor}>{vendor}</Option>
                    ))}
                </Select>
            ),
            width: '20%'
        },
        {
            title: '庫別',
            render: (_, record, index) => (
                <Select
                    value={completedProducts[index]?.庫別 || '未使用'}
                    onChange={value => handleInputChange(index, '庫別', value)}
                    disabled={!selectedProducts[index]}
                    style={{ width: '100%' }}
                >
                    <Option value="未使用" disabled>選擇庫別</Option>
                    {allLayers.map(layer => (
                        <Option key={layer} value={layer}>{layer}</Option>
                    ))}
                </Select>
            ),
            width: '20%'
        },
    ];

    return (
        <div>
            {loading && (
                <div>
                    <BouncyComponent />
                </div>
            )}
            {firstNewProducts.length > 0 && (
                <div className="modal-overlay">
                    <div className="modal-content" style={{ height: '100%' }}>
                        <h2>新品列表</h2>
                        <p>下面為本期新增品項，請勾選門市有使用的品項，並修改其廠商及庫別後提交</p>
                        <Table
                            columns={columns}
                            dataSource={firstNewProducts}
                            rowKey={(record) => record.商品編號}
                            pagination={true}
                            scroll={{ y: 400 }} // 設定固定高度及滾動
                            sticky
                            size="small"
                        />
                        <Button type="primary" onClick={saveCompletedProducts}>提交資料</Button>
                    </div>
                </div>
            )}
            {checkingConnections && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h2>連線檢查進度</h2>
                        <p>{checkProgress}</p>
                        <p>作業門市：{props.storeName === 'noStart' ? '檢查中...' : props.storeName}</p>
                        <p>伺服器：{serverConnected === null ? '檢查中...' : (serverConnected ? '在線' : '離線')}</p>
                        <p>EPOS 主機：{eposConnected === null ? '檢查中...' : (eposConnected ? '在線' : '離線')}</p>
                    </div>
                </div>
            )}
            <Modal
                isOpen={isModalOpen}
                title={modalContent.title}
                message={modalContent.message}
                type={modalContent.type}
                onClose={() => setIsModalOpen(false)}
            />
            <ConfirmModal
                isOpen={isConfirmModalOpen}
                title={confirmModalContent.title}
                message={confirmModalContent.message}
                onClose={() => setIsConfirmModalOpen(false)}
                onConfirm={() => {
                    if (modalAction) {
                        modalAction();
                        setModalAction(null);
                    }
                }}
            />
        </div>
    );
});

export default InventoryUploader;

