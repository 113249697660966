import React from 'react';
import { createRoot } from 'react-dom/client';
import { ConfigProvider } from 'antd';
 
import dayjs from 'dayjs'; // 确保正确导入 dayjs
import 'dayjs/locale/zh-tw'; // 导入需要的语言包
import zhTW from '../node_modules/antd/locale/zh_TW'; // 使用 antd 提供的语言包
import App from './App';


// 设置 Day.js 的语言环境
dayjs.locale('zh-tw');

// 获取根节点，并创建 React 根容器
const container = document.getElementById('root');
const root = createRoot(container);

// 渲染根组件，并使用 ConfigProvider 包裹
root.render(
    <ConfigProvider locale={zhTW} >
        <App style={{ overflow: 'hidden' }} />
        </ConfigProvider>
        );
