// app.js
import React, { useEffect, useState, useRef } from 'react';
import { Table, InputNumber, DatePicker, Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import axios from 'axios';
import io from 'socket.io-client';
import Swal from 'sweetalert2'
import ExportModal from './components/ExportModal';
import ArchiveModal from './components/ArchiveModal';
import GuideModal from './components/GuideModal';
import Modal from './components/Modal';
import Calculator from './components/Calculator';
import FilterModal from './components/FilterModal';
import BouncyComponent from './components/BouncyComponent';
import InventoryUploader from './components/InventoryUploader';
import InventoryItemsManager from './components/InventoryItemsManager';

import { setCookie, getCookie } from './utils/cookie';
import './components/style/Modal.css'; // 導入 Modal 的 CSS 樣式
import './components/Calculator.css'; // 導入 Modal 的 CSS 樣式
import dayjs from 'dayjs';
const socket = io('https://inventory.edc-pws.com'); //  連線到 Socket.IO 伺服器

const App = () => {
    // 狀態變數
    const [archivePassword, setArchivePassword] = useState(''); // 存儲歸檔密碼
    const [hasProducts, setHasProducts] = useState(false); // 新增狀態變數來追蹤產品列表是否存在

    const [showFunctionButtons, setShowFunctionButtons] = useState(false);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedVendors, setSelectedVendors] = useState([]);
    const [selectedLayers, setSelectedLayers] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [connectionStatus, setConnectionStatus] = useState('連線中...');
    const [showGuide, setShowGuide] = useState(false);
    const [isOffline, setIsOffline] = useState(false);
    const [errorModal, setErrorModal] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
    const [isInventoryItemsManagerOpen, setIsInventoryItemsManagerOpen] = useState(false);
    const [isExportModalOpen, setIsExportModalOpen] = useState(false);
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
    const cookieName = 'inventoryGuideShown';
    const [isReconnectPromptVisible, setIsReconnectPromptVisible] = useState(false);
    const inputRefs = useRef([]);
    const [hoveredProduct, setHoveredProduct] = useState(null);
    const [initialStock, setInitialStock] = useState(''); // 用於存儲期初庫存
    const [currentSpec, setCurrentSpec] = useState(''); // 用於存儲規格
    const [currentunit, setCurrentunit] = useState(''); // 用於存儲單位
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
    const [modalContent, setModalContent] = useState({});
    const [userCount, setUserCount] = useState(0);
    const archiveModalRef = useRef();
    const inventoryUploaderRef = useRef();
    const [storeName, setStoreName] = useState('新店京站');
    const [showCalculator, setShowCalculator] = useState(false);
    const [currentProductCode, setCurrentProductCode] = useState(null);
    const allVendors = ['全台', '央廚', '王座-備', '王座-食', '忠欣', '開元', '裕賀', '美食家', '點線麵'];
    const allLayers = ['未使用', '冷藏', '冷凍', '常温', '清潔', '備品'];
    const stores = ['台北京站', '新店京站', '信義威秀'];
    const disabledVendors = ['忠欣', '王座-備'];
    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    const [currentProductIndex, setCurrentProductIndex] = useState(null);
    const [calculatorVisible, setCalculatorVisible] = useState(false);
    const quantityInputRefs = useRef([]);
    const expiryDateInputRefs = useRef([]); // 到期日輸入框的引用
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
        }
    });

    // 產品數據加載函數

    useEffect(() => {


        const fetchProducts = async () => {
            setLoading(true);
            await delay(500); // 等待0.5秒
            try {
                const response = await axios.get(`https://inventory.edc-pws.com/api/products/${storeName}`);
                if (response.status === 100) {
                    setIsOffline(false);
                    setLoading(false);
                } else if (response.data.length === 0) {
                    Swal.fire({
                        title: '錯誤',
                        text: `無法盤點，因為尚未開始。`,
                        confirmButtonText: '開始盤點',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // 假設有一個名為 startInventory 的函式來開始盤點
                            startInventory();
                        }
                    });

                } else {
                    const updatedProducts = response.data.map(product => ({
                        ...product,
                        isExpiryDateEditable: !disabledVendors.includes(product.廠商), // 根據禁用廠商設定可編輯性
                    }));
                    setProducts(updatedProducts);
                    setIsOffline(false);
                    setHasProducts(true);


                }
            } catch (error) {
                console.error('取得產品失敗:', error);
                setIsOffline(true);
            } finally {
                setLoading(false);
            }
        };



        const guideShown = getCookie(cookieName); // 檢查 cookie 是否存在
        if (!guideShown) {
            // 如果 cookie 不存在，顯示說明手冊
            setTimeout(() => {
                setShowGuide(true);
                setCookie(cookieName, 'true'); // 設定 cookie，防止再顯示
            }, 1000);
        }

        if (storeName) {
            socket.emit('joinStoreRoom', storeName);
        }
        // 當 storeName 更改時，發送用戶加入房間
        // Socket.IO 事件監聽
        socket.on('updateUserCount', setUserCount);
        socket.on('productUpdated', (updatedProduct) => {
            setProducts(prevProducts =>
                prevProducts.map(product =>
                    product.商品編號 === updatedProduct.商品編號 ? updatedProduct : product
                )
            );

            // 使用 SweetAlert toast 代替傳統消息
            Toast.fire({
                icon: 'success',
                title: `${updatedProduct.商品名稱} 數量變更為 ${updatedProduct.數量}`
            });
        });
        fetchProducts(); // 調用 fetchProducts 函數`

        // 網路連線狀態監聽 (需要根據瀏覽器環境調整)
        const handleOnline = () => {
            Swal.fire('通知', '連線成功', 'success');
            setIsOffline(false);
        };
        const handleOffline = () => {
            Swal.fire('通知', '失去連線', 'warning');
            setIsOffline(true);
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);
        // 清除函式
        return () => {
            socket.off('updateUserCount', setUserCount);
            socket.off('productUpdated');
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
            socket.disconnect();
        };
    }, [storeName, setHasProducts]); // 將 setHasProducts 添加到依賴項中

    const handleStoreChange = (event) => {
        const newStoreName = event.target.value;
        setStoreName(newStoreName);
        socket.emit('joinStoreRoom', newStoreName); // 加入相應的房間
    };


    const startInventory = () => {
        if (inventoryUploaderRef.current) {
            inventoryUploaderRef.current.startInventory(); // 調用子組件的方法
        }
    };


    // 錯誤處理函式
    const handleError = (error, defaultMessage) => {
        const errorMessage = error.response ? error.response.data.message || error.response.data : error.message || defaultMessage;
        setErrorModal({ title: '錯誤訊息', message: errorMessage });
        setIsModalOpen(true);
    };


    const handleReconnect = () => {
        setConnectionStatus('連接成功 ✔');
        setIsUserOffline(false);
        setIsReconnectPromptVisible(false);

    };

    const handleReload = () => {
        window.location.reload(); // 重新加載頁面
        setIsModalOpen(false);
    };

    // 控制廠商篩選
    const handleVendorChange = (vendor) => {
        setSelectedVendors((prev) =>
            prev.includes(vendor) ? prev.filter(v => v !== vendor) : [...prev, vendor]
        );
    };

    // 控制庫別篩選
    const handleLayerChange = (layer) => {
        setSelectedLayers((prev) =>
            prev.includes(layer) ? prev.filter(l => l !== layer) : [...prev, layer]
        );
    };

    // 根據所選的廠商和庫別過濾產品
    const filteredProducts = products.filter(product => {
        const vendorMatch = selectedVendors.length === 0 || selectedVendors.includes(product.廠商);
        const layerMatch = selectedLayers.length === 0 || selectedLayers.includes(product.庫別);
        return vendorMatch && layerMatch;
    });
    //Enter切換下一個輸入框
    const handleKeyPress = (event, index) => {
        if (event.key === 'Enter') {
            const nextInput = inputRefs.current[index + 1];
            if (nextInput) {
                nextInput.focus(); // 將焦點移到下一個輸入框
            }
        }
    };

    //下載最新數量
    const updateQuantity = async (productCode, quantity) => {
        try {
            await axios.put(`https://inventory.edc-pws.com/api/products/${storeName}/${productCode}/quantity`, { 數量: quantity });
        } catch (error) {
            console.error("更新產品時出錯:", error);
        }
    };
    //下載最新校期
    const updateExpiryDate = async (productCode, expiryDate) => {
        try {
            await axios.put(`https://inventory.edc-pws.com/api/products/${storeName}/${productCode}/expiryDate`, { 到期日: expiryDate });
        } catch (error) {
            console.error("更新到期日時出錯:", error);
        }
    };

    // 上傳數量
    const handleQuantityChange = (productCode, quantity) => {
        const numQuantity = Number(quantity);
        if (isNaN(numQuantity) || numQuantity < 0) {
            alert('數量必須為非負數');
            return;
        }

        const updatedProducts = products.map(product => {
            if (product.商品編號 === productCode) {
                return { ...product, 數量: numQuantity }; // 更新數量
            }
            return product;
        });

        setProducts(updatedProducts);
        updateQuantity(productCode, numQuantity); // 上傳數量到資料庫
    };

    // 上傳到期日
    const handleExpiryDateChange = (productCode, expiryDate) => {
        const updatedProducts = products.map(product => {
            if (product.商品編號 === productCode) {
                return { ...product, 到期日: expiryDate }; // 更新到期日
            }
            return product;
        });

        setProducts(updatedProducts);
        updateExpiryDate(productCode, expiryDate); // 上傳到期日到資料庫
    };

    const handleMouseEnter = (product, e) => {
        setHoveredProduct(product.商品編號);
        // 直接使用 product 來設置初始庫存、規格和單位
        setInitialStock(product.期初庫存 ? `${product.期初庫存} ${product.單位}` : '查無歷史盤點紀錄');
        setCurrentSpec(product.規格 ? product.規格 : '未設定');
        setCurrentunit(product.單位 ? product.單位 : '');
        // 獲取當前鼠標位置
        const rect = e.currentTarget.getBoundingClientRect();
        setTooltipPosition({ top: e.clientY + 10, left: e.clientX + 10 });
    };

    const handleMouseLeave = () => {
        setHoveredProduct(null); // 清除懸停商品
        setInitialStock('');
        setCurrentSpec('');
        setCurrentunit('');
    };


    const openArchiveModal = () => {
        setIsArchiveModalOpen(true); // 打开模态框
    };

    const closeArchiveModal = () => {
        setIsArchiveModalOpen(false); // 关闭模态框
    };

    const handleQuantityClick = (index) => {
        setCurrentProductIndex(index);
        setCalculatorVisible(true); // 顯示計算器
    };

    const handleCalculate = (result) => {
        const updatedProducts = products.map((product, index) => {
            if (index === currentProductIndex) {
                return { ...product, 數量: result }; // 更新數量
            }
            return product;
        });
        setProducts(updatedProducts);
        setCalculatorVisible(false); // 隱藏計算器

        // 自動焦點移動到右側的到期日欄位
        if (currentProductIndex < products.length) {
            const currentProduct = products[currentProductIndex];
            const nextInput = currentProduct.isExpiryDateEditable
                ? expiryDateInputRefs.current[currentProductIndex]
                : quantityInputRefs.current[currentProductIndex + 1];

            if (nextInput) {
                nextInput.focus(); // 將焦點移至適當的欄位
            }
        }
    };

    const handleCloseCalculator = () => {
        setCalculatorVisible(false); // 關閉計算器
    };
    const getColumnSearchProps = (dataIndex) => ({
        // Custom column search logic (for text fields)
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={confirm}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={confirm}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}>
                    Search
                </Button>
                <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    });

    const columns = [
        {
            title: '商品編號',
            dataIndex: '商品編號',
            key: '商品編號',
            width: '15%',
            align: 'center',

        },
        {
            title: '商品名稱',
            dataIndex: '商品名稱',
            key: '商品名稱',
            ...getColumnSearchProps('商品名稱'),

            width: '55%',



        },
        {
            title: '數量',
            dataIndex: '數量',
            key: '數量',
            render: (text, product, index) => (
                <>
                    <InputNumber
                        value={text}
                        onChange={(value) => handleQuantityChange(product.商品編號, value)}
                        onClick={() => handleQuantityClick(index)}
                        style={{ width: '60px', textAlign: 'right' }}
                        ref={el => (quantityInputRefs.current[index] = el)}
                    />
                    &nbsp;{product.單位}
                </>
            ),
            width: '15%',
            align: 'center',


        },
        {
            title: '到期日',
            dataIndex: '到期日',
            key: '到期日',
            render: (text, product, index) => (
                <DatePicker
                    defaultValue={product.到期日 ? dayjs(product.到期日) : null}
                    onChange={(date, dateString) => handleExpiryDateChange(product.商品編號, dateString)}
                    style={{
                        backgroundColor: product.isExpiryDateEditable ? 'white' : '#f0f0f0',
                    }}
                    ref={el => (expiryDateInputRefs.current[index] = el)}
                    disabled={!product.isExpiryDateEditable}
                    format="YYYY-MM-DD"
                />
            ),
            width: '20%',
            align: 'center',


        },
    ];

    return (
        <>
            <div className="inventory-header">
                <div className="header-container">
                    <table className="header-table">
                        <thead>
                            <tr>
                                <td style={{ width: '60%' }} >
                                    <h1>庫存盤點系統</h1>
                                </td>

                                {/*<td rowSpan="2">
                                    <select value={storeName} onChange={handleStoreChange} >
                                        <option value="" disabled>請選擇門市</option>
                                        {stores.map((store, index) => (
                                            <option key={index} value={store}>{store}</option>
                                        ))}
                                    </select>
                                </td>*/}
                                <td style={{ width: '40%' }} className="header-table right">
                                    <Button
                                        className="header-button"
                                        onClick={() => setShowGuide(true)}
                                        type="default"
                                    >
                                        說明
                                    </Button>

                                    {!hasProducts ? (
                                        <Button onClick={startInventory} type="primary">
                                            盤點開始
                                        </Button>
                                    ) : (
                                        <>
                                            <Button className="header-button" onClick={openArchiveModal} type="default">
                                                歸檔
                                            </Button>
                                            <Button className="header-button" onClick={() => setIsExportModalOpen(true)} type="default">
                                                匯出
                                            </Button>
                                            <Button className="header-button" onClick={() => setIsInventoryItemsManagerOpen(true)} type="default">
                                                品項管理
                                            </Button>
                                        </>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="0" className="header-table.left" style={{ fontSize: '1em' }}>
                                    {storeName === 'noStart' ? '檢查中...' : (isOffline ? '失去連線 ❌' : '連線成功 ✔')}&nbsp;&nbsp;|&nbsp;&nbsp;在線共&nbsp;&nbsp;<strong>{userCount}</strong>&nbsp;&nbsp;人
                                </td>
                            </tr>
                        </thead>
                    </table>

                    <hr />
                    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', padding: '10px', margin: '5px', overflowX: 'auto', }} >
                        <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                            <strong>廠商</strong>：
                            <div className="list" style={{ display: 'flex', overflowX: 'auto', whiteSpace: 'nowrap', alignItems: 'flex-end' }}>

                                {allVendors.map(vendor => (
                                    <label key={vendor} className="filter-item" style={{ display: 'flex', margin: '0 6 10 8', alignItems: 'flex-top' }} >
                                        <input style={{ alignItems: 'flex-end', marginTop: '2px' }} type="checkbox" checked={selectedVendors.includes(vendor)} onChange={() => handleVendorChange(vendor)} /> {vendor}
                                    </label>
                                ))}
                            </div>
                        </div>

                        <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                            <strong>庫別</strong>：
                            <div className="list" style={{ display: 'flex', overflowX: 'auto', whiteSpace: 'nowrap', alignItems: 'flex-end' }}>

                                {['冷藏', '冷凍', '常溫', '清潔', '備品'].map(layer => (
                                    <label key={layer} className="filter-item" style={{ display: 'flex', margin: '0 6 10 8', alignItems: 'flex-top' }} >
                                        <input style={{ alignItems: 'flex-end', margin: '2 5 0 2' }} type="checkbox" checked={selectedLayers.includes(layer)} onChange={() => handleLayerChange(layer)} /> {layer}
                                    </label>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* 上半框架 - 廠商和庫別篩選 */}

                </div>
            </div>
            <div style={{ height: '27.7vh' }} ></div>
            {loading && (
                <div><BouncyComponent /></div>

            )}
            <div id="product-code-bottom">
                {filteredProducts.length > 0 && (
                    <form autoComplete='no'>
'
                        <div style={{ minWidth:'725px', maxWidth: '90%', margin: '0 auto', overflowX: 'auto' }}> {/* 設置最大寬度和水平置中 */}
                            <Table
                                columns={columns}
                                dataSource={filteredProducts.filter(product => product.庫別 !== '未使用')}
                                rowKey="商品編號"
                                pagination={false}
                              
                                size="small"
                                scroll={{ x: 700, y: 450 }} // 設定 x 軸的最大寬度
                            />
                        </div>
                    </form>
                )}
                {calculatorVisible && (
                    <div className="fixed-calculator">
                        <Calculator onCalculate={handleCalculate} onClose={handleCloseCalculator} />
                    </div>
                )}
            </div>

            {/* 其他 Modal 與提示框 */}
            <InventoryUploader ref={inventoryUploaderRef} storeName={storeName} />

            <ArchiveModal
                isOpen={isArchiveModalOpen}
                onClose={closeArchiveModal}
                products={products}
                storeName={storeName}
            />
            <InventoryItemsManager
                isOpen={isInventoryItemsManagerOpen}
                onClose={() => setIsInventoryItemsManagerOpen(false)}
                products={products}
                storeName={storeName} />

            <ExportModal
                isOpen={isExportModalOpen}
                onClose={() => setIsExportModalOpen(false)}
                products={products}
            />
            <GuideModal isOpen={showGuide} onClose={() => setShowGuide(false)} />
            {/* 顯示工具提示 */}
            {hoveredProduct && (<div style={{ textAlign: 'left', fontSize: '12px', position: 'fixed', backgroundColor: 'white', border: '1px solid #ccc', padding: '5px', borderRadius: '5px', zIndex: 1000, top: tooltipPosition.top, left: tooltipPosition.left, }}>
                期初存量：{initialStock} <br />
                規格：{currentSpec}
            </div>
            )}
            {/* 顯示載入提示*/}

            <Modal
                isOpen={isModalOpen}
                title={modalContent.title}
                message={modalContent.message}
                type={modalContent.type}
                onClose={() => setIsModalOpen(false)}
            />
            {/* 顯示錯誤訊息的 Modal */}
            {errorModal && (
                <Modal
                    isOpen={!!errorModal}
                    title={errorModal.title}
                    message={errorModal.message}
                    onClose={() => setErrorModal(null)}
                    type="error"
                />
            )}
            {/* 顯示離線提示 */}
            {isOffline && (
                <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.7)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000, }}>
                    <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px', textAlign: 'center' }}>
                        <h2>您已離線</h2>
                        <p>請檢查網路連線是否正常。</p>
                        <button onClick={() => window.location.reload()}>重新整理</button>
                    </div>
                </div>

            )
            }

            {/* 閒置提示框，顯示重新上線按鈕 */}
            {
                isReconnectPromptVisible && (
                    <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.7)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000, }}>
                        <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px', textAlign: 'center' }}>
                            <h2>閒置中斷線</h2>
                            <p>您已閒置超過10分鐘，請重新連接。</p>
                            <button onClick={handleReconnect}>重新上線</button>
                        </div>
                    </div>
                )
            }
            <footer style={{ position: 'fixed', bottom: '0', left: '0', right: '0', textAlign: 'center', padding: '3px', backgroundColor: '#f5f5f5', borderTop: '1px solid #ccc' }}>
                <p style={{ margin: '0px' }}>© 2024 edc-pws.com. All rights reserved.</p>
            </footer>
        </>

    );
};

export default App;
