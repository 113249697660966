import React, { useState, useRef, useEffect } from 'react';
import { create, all } from 'mathjs'; // 引入 mathjs

const math = create(all, {}); // 創建 mathjs 實例

const Calculator = ({ onCalculate, onClose }) => {
    const [input, setInput] = useState('');
    const [undoStack, setUndoStack] = useState([]); // 用來管理撤銷
    const [redoStack, setRedoStack] = useState([]); // 用來管理重做
    const calculatorRef = useRef(null); // 用來獲取計算器 DOM 元素

    const handleNumberClick = (num) => {
        setUndoStack((prev) => [...prev, input]); // 保存至撤銷堆疊
        setRedoStack([]); // 清空重做堆疊
        setInput((prev) => prev + num);
    };

    const handleOperatorClick = (operator) => {
        setUndoStack((prev) => [...prev, input]); // 保存至撤銷堆疊
        setRedoStack([]); // 清空重做堆疊
        setInput((prev) => prev + operator);
    };

    const calculateResult = () => {
        try {
            const result = math.evaluate(input); // 使用 mathjs 計算結果
            onCalculate(result);
            setUndoStack((prev) => [...prev, input]); // 將計算結果保存為歷史
            setInput(result.toString()); // 將結果顯示在輸入框
        } catch (error) {
            alert('計算錯誤：' + error.message);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // 防止表單提交
            calculateResult();
        }
    };

    const handleClear = () => {
        setInput('');
    };

    const handleUndo = () => {
        if (undoStack.length > 0) {
            const previousInput = undoStack.pop(); // 獲取前一個輸入
            setRedoStack((prev) => [...prev, input]); // 保存當前狀態至重做堆疊
            setInput(previousInput); // 更新輸入至前一個
        }
    };

    const handleRedo = () => {
        if (redoStack.length > 0) {
            const futureInput = redoStack.pop(); // 獲取重做狀態
            setUndoStack((prev) => [...prev, input]); // 保存當前狀態至撤銷堆疊
            setInput(futureInput); // 更新輸入至重做狀態
        }
    };
    const handleUpArrow = () => {
        if (currentProductIndex > 0) {
            // 切換至上一行數量框
            const previousInput = input; // 你可以根據需求保留當前輸入
            const newIndex = currentProductIndex - 1;
            onClose(previousInput, newIndex); // 更新上方产品索引
        }
    };

    const handleDownArrow = () => {
        if (currentProductIndex < products.length - 1) {
            // 切換至下一行數量框
            const nextInput = input; // 你可以根據需求保留當前輸入
            const newIndex = currentProductIndex + 1;
            onClose(nextInput, newIndex); // 更新下方产品索引
        }
    };
    // 點擊計算器外部的關閉處理
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (calculatorRef.current && !calculatorRef.current.contains(event.target)) {
                onClose(); // 關閉計算器
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    return (
        <div ref={calculatorRef} className="calculator" style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                    type="text"
                    style={{ width: '95%', textAlign: 'right', padding: '10px' }}
                    value={input}
                    readOnly
                    onKeyDown={handleKeyDown}
                />
                <img
                    src='/images/check.svg'
                    alt="check"
                    onClick={calculateResult}
                    style={{ borderRadius: '5px', backgroundColor: 'dimgray', cursor: 'pointer', marginLeft: '5px', width: '40px', height: '40px' }}
                />
                <img
                    src='/images/backspace.svg' // 更新為 backspace 圖
                    alt="backspace"
                    onClick={() => {
                        setInput((prev) => prev.slice(0, -1)); // 減去一個字符
                    }}
                    style={{
                        borderRadius: '5px', backgroundColor: 'dimgray', cursor: 'pointer', marginLeft: '5px', width: '40px', height: '40px' }}
                />
                <img
                    src='/images/undo.svg'
                    alt="undo"
                    onClick={handleUndo}
                    style={{ borderRadius: '5px', backgroundColor: 'dimgray', cursor: 'pointer', marginLeft: '5px', width: '40px', height: '40px' }}
                />
                <img
                    src='/images/redo.svg'
                    alt="redo"
                    onClick={handleRedo}
                    style={{ borderRadius: '5px', backgroundColor: 'dimgray', cursor: 'pointer', marginLeft: '5px', width: '40px', height: '40px' }}
                />
            </div>
            <div className="calculator-buttons">
                <button onClick={() => handleNumberClick('7')}>7</button>
                <button onClick={() => handleNumberClick('8')}>8</button>
                <button onClick={() => handleNumberClick('9')}>9</button>
                <button onClick={() => handleOperatorClick('/')}>÷</button>
                <button onClick={handleUpArrow}><img src='/images/keyboard_arrow_up.svg' alt="up" /></button>

                <button onClick={() => handleNumberClick('4')}>4</button>
                <button onClick={() => handleNumberClick('5')}>5</button>
                <button onClick={() => handleNumberClick('6')}>6</button>
                <button onClick={() => handleOperatorClick('*')}>×</button>
                <button onClick={handleUpArrow}><img src='/images/keyboard_arrow_down.svg' alt="down" /></button>

                <button onClick={() => handleNumberClick('1')}>1</button>
                <button onClick={() => handleNumberClick('2')}>2</button>
                <button onClick={() => handleNumberClick('3')}>3</button>
                <button onClick={() => handleOperatorClick('-')}>-</button>
                <button onClick={() => handleOperatorClick('(')}>(</button>


                <button onClick={() => handleNumberClick('0')}>0</button>
                <button onClick={() => handleOperatorClick('.')}>.</button>
                <button onClick={calculateResult}>=</button>
                <button onClick={() => handleOperatorClick('+')}>+</button>
                <button onClick={() => handleOperatorClick(')')}>)</button>

            </div>
        </div>
    );
};

export default Calculator;
