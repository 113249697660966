import React, { useState, useEffect } from 'react';
import { AudioOutlined, DownOutlined, UserOutlined } from '@ant-design/icons';
import { Collapse, Input, Space, Table, Button, Dropdown, message, Tooltip, Select, Checkbox } from 'antd';
import { ProCard, ProFormGroup, ProFormSwitch } from '@ant-design/pro-components';

import axios from 'axios';
import '../components/style/Modal.css'; // 導入 Modal 的 CSS 樣式

const InventoryItemsManager = ({ isOpen, onClose, storeName }) => {
    const [inventoryItems, setInventoryItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const allVendors = [ '全台', '央廚', '王座-備', '王座-食', '忠欣', '開元', '裕賀', '美食家', '點線麵'];
    const allLayers = [ '冷藏', '冷凍', '常溫', '清潔', '備品'];
    const { Search } = Input;
    const { Panel } = Collapse;

    const rowStyle = { height: '20px', padding: '5px', margin: '5px' };
    useEffect(() => {
        const fetchInventoryItems = async () => {
            try {
                const response = await axios.get(`https://inventory.edc-pws.com/api/products/${storeName}`);
                setInventoryItems(response.data);
            } catch (error) {
                console.error('Failed to fetch inventory items:', error);
            }
        };

        fetchInventoryItems();
    }, [storeName]);
    const handleCheckboxChange = (item, checked) => {
        const updatedItems = inventoryItems.map(i => {
            if (i.商品編號 === item.商品編號) {
                return { ...i, 庫別: checked ? '尚未選擇' : '未使用' }; // 根據勾選狀態更新庫別
            }
            return i;
        });

        setInventoryItems(updatedItems);
    };


    const handleInputChange = (productCode, field, value) => {
        const updatedItems = inventoryItems.map(item => {
            if (item.商品編號 === productCode) {
                return { ...item, [field]: value }; // 更新指定欄位
            }
            return item;
        });
        setInventoryItems(updatedItems);
    };

    const saveCompletedItems = async () => {
        try {
            await Promise.all(inventoryItems.map(async (item) => {
                await axios.put(`https://inventory.edc-pws.com/api/products/${storeName}/${item.商品編號}`, {
                    庫別: item.庫別,
                    廠商: item.廠商,
                });
            }));
            alert('數據提交成功！');
            onClose(); // 提交成功後關閉模組
        } catch (error) {
            console.error('提交數據失敗:', error);
        }
    };

    // 篩選功能
    const filteredItems = inventoryItems.filter(item =>
        item.商品名稱.includes(searchTerm) || item.商品編號.includes(searchTerm) || item.廠商.includes(searchTerm) || item.庫別.includes(searchTerm)
    );

    // 設置表格的列
    const columns = [
        {
            title: '',
            dataIndex: '選擇',
            render: (_, item) => (
                <Checkbox
                    checked={item.庫別 !== '未使用'} // 根據庫別值設定勾選狀態
                    onChange={(e) => handleCheckboxChange(item, e.target.checked)}
                />
            ),
            width: 50,
            height: 20,
            align: 'center',
        },
    {
        title: '編號',
        id: '編號',
        dataIndex: '商品編號',
        render: (_, item) => item.商品編號,
        width: '105px',
        height: 20,
        align: 'center',
        }, 

{
    title: '品名',
        dataIndex: '商品名稱',
    render: (_, item) => item.商品名稱,
    width: 'auto',
    align: 'center',
                height: 20,

        },
{
    title: '廠商',
    dataIndex: '廠商',
    render: (_, item) => (
        <Select style={{ border: '0px', padding: '0' }}
            value={item.廠商}
            onChange={value => handleInputChange(item.商品編號, '廠商', value)}
            disabled={item.庫別 === '未使用'} // 根據庫別值控制欄位可編輯性
        >
            <Select.Option value="" disabled>尚未選擇</Select.Option>
            {allVendors.map(vendor => (
                <Select.Option key={vendor} value={vendor}>{vendor}</Select.Option>
            ))}
        </Select>
    ),
    width: '120px',
    align: 'center',
        },
{
    title: '庫別',
    dataIndex: '庫別',
    render: (_, item) => (
        <Select
            value={item.庫別}
            onChange={value => handleInputChange(item.商品編號, '庫別', value)}
            disabled={item.庫別 === '未使用'} // 根據庫別值控制欄位可編輯性
        >
            <Select.Option value="未使用" disabled>尚未選擇</Select.Option>
            {allLayers.map(layer => (
                <Select.Option key={layer} value={layer}>{layer}</Select.Option>
            ))}
        </Select>
    ),
    width: '120px',
    align: 'center',
    height: 20,

        },
    ];

if (isOpen) {
    return (
        <div className="modal-overlay" >
            <div className="modal-content" style={{ overflow: 'hidden' }}>
                <h2>盤點品項管理</h2>
                <Space style={{ width: '90%', marginLeft: '70%', marginTop: '20px', marginBottom: '10px', }}>
                    <div className="mobile-view">
                    <Input
                        placeholder="搜尋..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ width: '100%' }}
                    /> </div>

                </Space>
                           {/* 行動裝置模式 */}
            
                <Table
                    className="in-td"
                    bordered
                    dataSource={filteredItems}
                    columns={columns}
                    rowKey="商品編號"
                    pagination={true} // 可以根據需求決定是否需要翻頁功能
                    scroll={{ y: 300 }} // 可以設置固定表頭
                    size="small"
                    components={{
                        body: {
                            row: (props) => (
                                <tr {...props} style={rowStyle} /> // 設定內聯樣式
                            ),
                        },
                    }}
                />

                <button onClick={saveCompletedItems}>提交資料</button>
                <button onClick={onClose}>取消</button>
            </div>
        </div>
    );
}

return null; // 若未開啟模組則返回 null
};

export default InventoryItemsManager;
